<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                All Sales ({{saleData.length}})
              </span>
              <UInput v-model="dataTableSearch" size="md" placeholder="Search sale" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="saleData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row, rowId}">
                <Cell
                    v-for="(cellData, key) in row"
                    class="text-nowrap"
                    :cell-classes="[
                        // Center
                        ['selected', 'status', 'resale'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'status' || key === 8" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else>{{cellData}}</span>
                </Cell>
                <Cell>
                  <button class="btn btn-info btn-sm" @click="saleDetails(rowId)">Details</button>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import saleService from "@/api/saleService";
import router from "@/router";
import {decrypt} from "@/utils/crypto";
import confirmDialog from "@/utils/confirmDialog";
import formatDate from "@/utils/formatDate";
import formatNumber from "@/utils/formatNumber";

export default {
  name: "Sales",

  data(){
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    return {
      organization: {},
      store: {},
      profile: {},
      loading: true,
      dataTableHeaders: [
        '#',
        'Reference',
        'Store',
        'By User',
        'Discount',
        'Tax',
        'Total',
        'Source',
        'Status',
        'Date',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 10,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    confirmDialog,
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'pending':
          return 'warning';
        case 'complete':
          return 'success';
      }
    },
    async getSales() {
      this.dataTable = await saleService.getAllSales()
      this.loading = false
    },
    saleDetails(id) {
      router.push('/sale/' + id)
    },
  },

  computed: {
    saleData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          this.dataTable[i].reference,
          this.dataTable[i].store.code + ' - ' + this.dataTable[i].store.name,
          this.dataTable[i].user.first_name + ' ' + this.dataTable[i].user.last_name,
          formatNumber(this.dataTable[i].discount),
          formatNumber(this.dataTable[i].tax),
          formatNumber(this.dataTable[i].total),
          this.dataTable[i].source,
          this.dataTable[i].status,
          formatDate(this.dataTable[i].created_at),
        ];
        data.push(fields);
      }
      return data;
    }
  },

  created: async function () {
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    await this.getSales()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

}
</script>

<style scoped>

</style>
