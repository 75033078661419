'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";

export default {
  async getSuppliers() {
    const data = {
      method: 'get',
      url: 'suppliers',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createSupplier(json) {
    const data = {
      method: 'post',
      url: 'supplier',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Supplier created successfully')
      await router.push('/supplier/' + response.data.id)
    }
  },
  async getSupplier(id) {
    const data = {
      method: 'get',
      url: 'supplier/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async updateSupplier(json, id) {
    const data = {
      method: 'put',
      url: 'supplier/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Supplier updated successfully')
      await router.push('/supplier/' + response.data.id)
    }
  },
  async getSupplierNextNo() {
    const data = {
      method: 'get',
      url: 'supplierNextNo',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getProductsBySupplier(id) {
    const data = {
      method: 'get',
      url: 'supplier/' + id + '/products',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
}
