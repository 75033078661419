<template>
  <Card>
    <template slot="header">Product Details</template>
    <div class="row">
      <div v-if="loading" class="text-center">
        <Spinner spinner-class="mb-1" />
      </div>
      <div v-if="!loading" class="col-md-12">
        <h2 class="text-center">{{ product.name }}</h2>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div v-if="!loading" class="col-md-12 row">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">SKU</h6>
            <p class="fst-italic">{{ product.sku }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Code</h6>
            <p class="fst-italic">{{ product.code }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Stock</h6>
            <p class="fst-italic">{{ product.stock + ' ' + product.unit.name }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Reorder</h6>
            <p class="fst-italic">{{ product.reorder + ' ' + product.unit.name }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Buying Price</h6>
            <p class="fst-italic">{{ formatNumber(product.buy) }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Selling Price</h6>
            <p class="fst-italic">{{ formatNumber(product.sell) }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Category</h6>
            <p class="fst-italic">{{ product.category.name }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Supplier</h6>
            <p class="fst-italic">{{ product.supplier.name }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Tax Category</h6>
            <p class="fst-italic">{{ product.tax_category.description + ' - ' + product.tax_category.rate }}%</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Status</h6>
            <p class="fst-italic">
              <span :class="`badge bg-${product.status === 'active' ? 'success' : 'danger'}`">{{ product.status }}</span>
            </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Reorder Status</h6>
            <p class="fst-italic">
              <span :class="`badge bg-${product.reorder < product.stock ? 'success' : 'danger'}`">
                {{ product.reorder < product.stock ? 'good' : 'reorder' }}
              </span>
            </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Description</h6>
            <p class="fst-italic">{{ product.description }}</p>
          </div>
        </div>
      </div>

      <hr class="border-1 border-info border-opacity-75 mt-5" />
      <div class="col-md-12 d-flex justify-content-center">
        <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
        <button
            v-if="profile.role !== 'user' && profile.role !== 'admin'"
            class="btn btn-primary text-uppercase"
            @click="$router.push({path: '/edit-product/' + $route.params.id})"
        >Edit</button>
      </div>
    </div>
  </Card>
</template>

<script>
import productService from "@/api/productService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";
import formatNumber from "../../utils/formatNumber";

export default {
  name: "ProductDetails",

  data() {
    return {
      profile: {},
      loading: true,
      showModal: false,
      product: {},
      imagesUrl: process.env.VUE_APP_API_IMAGE_URL + 'product/',
    }
  },

  methods: {
    formatNumber,
    formatDate,
    async getProduct() {
      this.product = await productService.getProduct(this.$route.params.id)
      this.loading = false
    },
    submitForm: async function () {
      let response = await productService.updateProduct(this.product)
      if (response) {
        this.showModal = false
        await this.getProduct()
      }
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getProduct()
  },

}
</script>
