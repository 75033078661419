<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                {{ store.name }} Stock Takes ({{stockTakeData.length}})
                <span class="pb-5">
                  <button
                      v-if="profile.role !== 'user'"
                      class="btn btn-success btn-sm"
                      @click="stockTakeModal = true; stockTakeMode = 'add'"><i class="fa fa-plus"></i> Add Stock Take
                  </button>
                </span>
              </span>
              <UInput v-model="dataTableSearch" size="md" placeholder="Search stock take" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="stockTakeData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row, rowId}">
                <Cell
                    v-for="(cellData, key) in row"
                    class="text-nowrap"
                    :cell-classes="[
                        // Center
                        ['selected', 'status', 'reorder'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'status' || key === 5" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else>{{cellData}}</span>
                </Cell>
                <Cell>
                  <Button class="btn-info btn-sm" @click="stockTakeDetails(rowId)">Details</Button>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add StockTake Modal -->
    <Modal :open="stockTakeModal" @closed="stockTakeModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="stockTakeMode === 'add'">Add Stock Take</h5>
        <h5 class="modal-title m-0" v-else>Edit Stock Take</h5>
        <button @click="stockTakeModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Code" v-model="stockTake.code" readonly>
            </div>
            <div class="form-group col-md-8">
              <label for="description" class="form-label fw-bold h5 mt-3">Description:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Description" v-model="stockTake.description">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="stockTakeModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="stockTakeMode === 'add'" @click="createStockTake">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateStockTake(stockTake.id)">Update</button>
      </div>
    </Modal>
    <!-- Add StockTake Modal End -->

  </div>
</template>

<script>
import stockTakeService from "@/api/stockTakeService";
import router from "@/router";
import {decrypt} from "@/utils/crypto";
import formatDate from "@/utils/formatDate";

export default {
  name: "StockTakes",

  data(){
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    return {
      organization: {},
      store: {},
      profile: {},
      loading: true,
      stockTake: {
        organization_id: this.organization.id,
        store_id: this.store.id,
        user_id: this.profile.id,
      },
      stockTakeMode: 'add',
      stockTakeModal: false,
      dataTableHeaders: [
        '#',
        'Code',
        'Store',
        'By User',
        'Description',
        'Status',
        'Date',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 10,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'pending':
          return 'warning';
        case 'complete':
          return 'success';
      }
    },
    async getStockTakes() {
      this.dataTable = await stockTakeService.getStockTakes(this.store.id)
      let nextNo = await stockTakeService.getStockTakeNextNo(this.store.id)
      this.stockTake.code = nextNo.next
      this.loading = false
    },
    stockTakeDetails(id) {
      router.push('/stock-take/' + id)
    },
    async createStockTake() {
      let response = await stockTakeService.createStockTake(this.stockTake)
      if (response) {
        this.stockTakeModal = false
        await this.getStockTakes()
      }
    },
  },

  computed: {
    stockTakeData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          this.dataTable[i].code,
          this.dataTable[i].store.code + ' - ' + this.dataTable[i].store.name,
          this.dataTable[i].user.first_name + ' ' + this.dataTable[i].user.last_name,
          this.dataTable[i].description,
          this.dataTable[i].status,
          formatDate(this.dataTable[i].created_at),
        ];
        data.push(fields);
      }
      return data;
    }
  },

  created: async function () {
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
    await this.getStockTakes()
  },

}
</script>

<style scoped>

</style>
