'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getStoreStock(id) {
        const data = {
            method: 'get',
            url: 'store/' + id + '/stock',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async updateStoreStock(json, id) {
        const data = {
            method: 'put',
            url: 'stock/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Store stock updated successfully')
            return true;
        }
    }
}
