'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";

export default {
    async getProducts() {
        const data = {
            method: 'get',
            url: 'products',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getProductNextNo() {
        const data = {
            method: 'get',
            url: 'productNextNo',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getProduct(id) {
        const data = {
            method: 'get',
            url: 'product/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createProduct(json) {
        const data = {
            method: 'post',
            url: 'product',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Product added successfully')
            await router.push('/product/' + response.data.id)
        }
    },
    async updateProduct(json, id) {
        const data = {
            method: 'put',
            url: 'product/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Product updated successfully')
            router.push('/product/' + id)
        }
    },
    async uploadProductFile(json) {
        const data = {
            method: 'post',
            url: 'product/upload',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Products created successfully')
            return true;
        }
    },
    async getProductFiles(id) {
        const data = {
            method: 'get',
            url: 'product/files/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
}
