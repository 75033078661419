'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
  async getStockTakes(id) {
    const data = {
      method: 'get',
      url: 'stock-takes/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createStockTake(json) {
    const data = {
      method: 'post',
      url: 'stock-take',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Stock take created successfully')
      return true;
    }
  },
  async getStockTake(id) {
    const data = {
      method: 'get',
      url: 'stock-take/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async updateStockTake(json, id) {
    const data = {
      method: 'put',
      url: 'stock-take/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Stock take updated successfully')
      return true;
    }
  },
  async getStockTakeNextNo(id) {
    const data = {
      method: 'get',
      url: 'stock-takeNextNo/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getStockTakeDetails(id) {
    const data = {
      method: 'get',
      url: 'stock-take/' + id + '/details',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createStockTakeDetails(json) {
    const data = {
      method: 'post',
      url: 'stock-take-details',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Stock take product added', 2000)
      return true;
    }
  },
  async updateStockTakeDetails(json, id) {
    const data = {
      method: 'put',
      url: 'stock-take-details/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Stock take product updated', 2000)
      return true;
    }
  },
  async deleteStockTakeDetails(id) {
    const data = {
      method: 'delete',
      url: 'stock-take-details/' + id
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Stock take product deleted', 2000)
      return true;
    }
  },
  async approveStockTake(id) {
    const data = {
      method: 'patch',
      url: 'stock-take-approve/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Stock take approved successfully')
      window.location.reload();
    }
  },
}
