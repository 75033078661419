'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
  async getAllOrders() {
    const data = {
      method: 'get',
      url: 'store-orders',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getOrders(id) {
    const data = {
      method: 'get',
      url: 'store-orders/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createOrder(json) {
    const data = {
      method: 'post',
      url: 'store-order',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Order created successfully')
      return true;
    }
  },
  async getOrder(id) {
    const data = {
      method: 'get',
      url: 'store-order/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async updateOrder(json, id) {
    const data = {
      method: 'put',
      url: 'store-order/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Order updated successfully')
      return true;
    }
  },
  async getOrderNextNo() {
    const data = {
      method: 'get',
      url: 'store-orderNextNo',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getOrderDetails(id) {
    const data = {
      method: 'get',
      url: 'store-order/' + id + '/details',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createOrderDetails(json) {
    const data = {
      method: 'post',
      url: 'store-order-details',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Order product added', 2000)
      return true;
    }
  },
  async updateOrderDetails(json, id) {
    const data = {
      method: 'put',
      url: 'store-order-details/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Order product updated', 2000)
      return true;
    }
  },
  async deleteOrderDetails(id) {
    const data = {
      method: 'delete',
      url: 'store-order-details/' + id
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Order product deleted', 2000)
      return true;
    }
  },
  async changeOrderStatus(body, id) {
    const data = {
      method: 'patch',
      url: 'store-order-status/' + id,
      body: body,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Order submitted successfully')
      window.location.reload();
    }
  },
}
