<template>
  <div>
    <div class="col-md-12 mb-2">
      <div class="col-md-12 d-flex justify-content-between">
        <vue-feather class="nav" type="arrow-left-circle" @click="$router.go(-1)" stroke="#000000" size="35" stroke-width="1"></vue-feather>
        <vue-feather class="nav" type="log-out" @click="logout" stroke="#fc0303" size="35" stroke-width="1"></vue-feather>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
    </div>
    <div class="row pt-1">
      <div class="col-xxl-8">
        <div class="row">
          <div class="col-12">
            <Card body-classes="p-0" class="mb-5">
              <div v-if="loading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
              <div v-if="!loading">
                <UInput v-model="dataTableSearch" size="lg" placeholder="Search product..." class="mb-3 border-2 w-100"/>
                <DataTable show-filter-buttons :data="productData" :headers="dataTableHeaders"
                           :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage" v-if="dataTableShow">
                  <template v-slot:default="{row}">
                    <Cell
                        v-for="(cellData, key) in row"
                        :class="row[10] === true ? 'bg-info-light' : ''"
                        class="text-nowrap"
                        style="cursor: pointer;"
                        @click="addCartProduct(row)"
                        :cell-classes="[
                        // Center
                        ['selected', 'status', 'reorder'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                      <span v-if="key === 0">{{cellData.split("-").pop()}}</span>
                      <span v-else>{{cellData}}</span>
                    </Cell>
                  </template>
                </DataTable>
              </div>
            </Card>
          </div>
        </div>

        <div class="card">
          <div>
            <div class="table-responsive px-md-4 px-2 pt-3">
              <table class="table table-borderless text-nowrap">
                <tbody>
                <tr v-if="cartAvailable">No products in cart</tr>
                <tr v-else v-for="cart in cartProducts" :key="cart.id" class="border-bottom">
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="d-flex flex-column">
                        <p class="text-muted">{{ cart.product.sku }}
                          <span class="ps-1 fw-bold">{{ cart.product.name }}</span>
                        </p>
                        <small class=" d-flex">
                          <span class=" text-muted">{{ cart.product.description }}</span>
                        </small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <p class="pe-3">
                        <span class="red">{{ formatNumber(cart.price) }}</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center form-group">
                      <div class="input-group">
                        <span class="input-group-btn ms-xxl-5">
                          <button
                              @click="quantityButtons(cart.product_id, 'minus')"
                              class="btn btn-default"
                              :class="cart.quantity <= 1 ? 'disabled' : ''"
                          >
                            <span>-</span>
                          </button>
                        </span>
                        <input
                            :ref="cart.product_id"
                            type="text"
                            @change="updateCartProduct(cart.product_id, cart.quantity)"
                            class="form-control text-center border-1 border-primary"
                            v-model="cart.quantity"
                        >
                        <span class="input-group-btn me-xxl-5">
                          <button
                              @click="quantityButtons(cart.product_id, 'plus')"
                              class="btn btn-default"
                          >
                            <span>+</span>
                          </button>
                        </span>
                        <span class="text-muted">&nbsp;{{ cart.product.unit.name }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <p class="pe-3">
                        <span class="red">{{ formatNumber(cart.price * cart.quantity) }}</span>
                        <span class="text-muted fw-bold">&nbsp;{{ cart.product.tax_category.label }}</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <p class="pe-3">
                        <span
                            class="fw-bold btn btn-sm btn-outline-danger"
                            style="cursor: pointer"
                            @click="removeCartProduct(cart.product_id)"
                        >x</span>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="text-end fw-bold">
                    Total: <span class="red">{{ formatNumber(calculateTotal) }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-4 payment-summary">
        <div class="card px-md-3 px-2 pt-4">
          <div class="ribbon ribbon-top-right"><span>Sale</span></div>
          <div class="unregistered mb-4"> <span class="py-1">unregistered account</span> </div>
          <div class="d-flex justify-content-between pb-3"> <small class="text-muted">Transaction code</small>
            <p class="">VC115665</p>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <span class="text-muted fw-bold">Discount: </span>
            <input type="number" class="form-control w-50" v-model="discount" min="0" placeholder="DISCOUNT">
          </div>
          <div class="d-flex justify-content-between mt-3">
            <span class="text-muted fw-bold">Payment: </span>
            <div>
              <input type="radio" id="cash" v-model="transaction.method" value="cash">
              <label for="cash" class="form-check-label">Cash</label>
            </div>
            <div>
              <input type="radio" id="mpesa" v-model="transaction.method" value="mpesa">
              <label for="mpesa" class="form-check-label">MPESA</label>
            </div>
          </div>
          <div class="d-flex justify-content-between b-bottom mt-3">
            <div>
              <span class="text-muted fw-bold">Amount: </span>
              <input type="number" class="form-control" v-model="transaction.amount" :min="calculateTotal - discount" placeholder="AMOUNT">
            </div>
            <div v-if="transaction.method === 'mpesa'">
              <span class="text-muted fw-bold">Ref: </span>
              <input type="text" class="form-control" v-model="transaction.reference" placeholder="REFERENCE">
            </div>
          </div>
          <div class="d-flex flex-column table-responsive b-bottom">
            <table class="table table-borderless table-sm text-nowrap">
              <tbody>
              <tr>
                <td class="text-end fw-bold">
                  Subtotal: <span class="red">{{ formatNumber(calculateTotal - calculateTax) }}</span>
                </td>
              </tr>
              <tr>
                <td class="text-end fw-bold">
                  Tax: <span class="red">{{ formatNumber(calculateTax) }}</span>
                </td>
              </tr>
              <tr v-if="discount > 0">
                <td class="text-end fw-bold">
                  Discount: <span class="red">{{ formatNumber(discount) }}</span>
                </td>
              </tr>
              <tr v-if="transaction.amount > (calculateTotal - discount)">
                <td class="text-end fw-bold h5">
                  Change: <span class="red">{{ formatNumber(transaction.amount - (calculateTotal - discount)) }}</span>
                </td>
              </tr>
              <tr>
                <td class="text-end fw-bold h1">
                  Total: <span class="red">{{ formatNumber(calculateTotal - discount) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="my-3">
            <button
                :class="cartAvailable ? 'disabled' : ''"
                class="btn btn-primary w-100 text-uppercase fs-4 py-2"
                @click="validate">Make Sale
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <!-- QuickLinks -->
          <div class="col-12">
            <Card>
              <div class="row">
                <div class="col-xxl-4 col-lg-3 col-md-4 col-sm-6 col-6 text-center quick-link" @click="$router.push({path: 'dashboard'})">
                  <div class="bg-info-subtle rounded p-3 mb-4 text-black">
                    <vue-feather type="home" stroke="#3db6ba" size="60" stroke-width="2"></vue-feather>
                    <p class="text-uppercase">Home</p>
                  </div>
                </div>
                <div class="col-xxl-4 col-lg-3 col-md-4 col-sm-6 col-6 text-center quick-link" @click="$router.push({path: 'dashboard'})">
                  <div class="bg-primary-light rounded p-3 mb-4 text-black">
                    <vue-feather type="trash-2" stroke="#652aa3" size="60" stroke-width="2"></vue-feather>
                    <p class="text-uppercase">Clear</p>
                  </div>
                </div>
                <div class="col-xxl-4 col-lg-3 col-md-4 col-sm-6 col-6 text-center quick-link" @click="$router.push({path: 'dashboard'})">
                  <div class="bg-success-light rounded p-3 mb-4 text-black">
                    <vue-feather type="refresh-cw" stroke="#2aa367" size="60" stroke-width="2"></vue-feather>
                    <p class="text-uppercase">Refresh</p>
                  </div>
                </div>
                <div class="col-xxl-4 col-lg-3 col-md-4 col-sm-6 col-6 text-center quick-link" @click="$router.push({path: 'dashboard'})">
                  <div class="bg-warning-light rounded p-3 mb-4 text-black">
                    <vue-feather type="arrow-left-circle" stroke="#c2b721" size="60" stroke-width="2"></vue-feather>
                    <p class="text-uppercase">Back</p>
                  </div>
                </div>
                <div class="col-xxl-4 col-lg-3 col-md-4 col-sm-6 col-6 text-center quick-link" @click="$router.push({path: 'dashboard'})">
                  <div class="bg-secondary-light rounded p-3 mb-4 text-black">
                    <vue-feather type="log-out" stroke="#b54d24" size="60" stroke-width="2"></vue-feather>
                    <p class="text-uppercase">Log out</p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {decrypt} from "@/utils/crypto";
import formatDate from "@/utils/formatDate";
import formatNumber from "@/utils/formatNumber";
import confirmDialog from "@/utils/confirmDialog";
import posService from "@/api/posService";
import saleService from "@/api/saleService";
import authService from "@/api/authService";
import notify from "@/utils/notify";

export default {
  name: "StockTakeDetails",

  data(){
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    return {
      profile: {},
      store: {},
      organization: {},
      discount: 0,
      transaction: {
        organization_id: this.organization.id,
        amount: 0,
        method: 'cash',
        reference: '',
      },
      cartProducts: [],
      dataTableShow: false,
      selectedProduct: {
        product: {},
      },
      loading: true,
      dataTableHeaders: [
        '#',
        'SKU',
        'Code',
        'Name',
        'Price'
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 5,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    formatDate,
    formatNumber,
    confirmDialog,
    async getStoreProducts() {
      this.dataTable = await posService.getPOSProducts(this.store.id)
      this.loading = false
    },
    addCartProduct(product) {
      this.selectedProduct = this.getSelectedProduct(product[0])
      let cart = sessionStorage.getItem('cart')
      if (cart === null) {
        this.selectedProduct.quantity = 1
        sessionStorage.setItem('cart', JSON.stringify([this.selectedProduct]))
      } else {
        let data = JSON.parse(cart)
        let cartItems = data.filter(
            item => item.product_id.indexOf(product[0]) > -1
        )
        if (cartItems.length === 1) {
          cartItems[0].quantity++
        } else {
          this.selectedProduct.quantity = 1
          data.push(this.selectedProduct)
        }
        sessionStorage.setItem('cart', JSON.stringify(data))
      }
      this.getCartProducts()
    },
    updateCartProduct(productId, quantity) {
      if (quantity >= 1) {
        let data = JSON.parse(sessionStorage.getItem('cart'))
        data = data.map(item => {
          if (item.product_id === productId) {
            item.quantity = quantity
          }
          return item
        })
        sessionStorage.setItem('cart', JSON.stringify(data))
        this.getCartProducts()
      } else {
        this.removeCartProduct(productId)
      }
    },
    removeCartProduct(productId) {
      let data = JSON.parse(sessionStorage.getItem('cart'))
      data = data.filter(
          item => item.product_id.indexOf(productId) === -1
      )
      sessionStorage.setItem('cart', JSON.stringify(data))
      this.getCartProducts()
    },
    getSelectedProduct(id) {
      let data = this.dataTable.filter(
          item => item.product_id.indexOf(id) > -1
      )
      return data[0]
    },
    getCartProducts() {
      let cart = sessionStorage.getItem('cart')
      if (cart === null) {
        sessionStorage.setItem('cart', JSON.stringify([]))
      } else {
        this.cartProducts = JSON.parse(cart)
      }
      this.dataTableSearch = ''
    },
    quantityButtons(id, type) {
      let element = this.$refs[id][0]
      let currentVal = parseInt(element.value)
      if (!isNaN(currentVal)) {
        if(type === 'minus') {
          if(currentVal > 1) {
            element.value = currentVal - 1
          } else {
            element.disabled = true
          }
        } else if(type === 'plus') {
          element.value = currentVal + 1
        }
      } else {
        element.value = 1
      }
      this.updateCartProduct(id, parseInt(element.value))
    },
    validate() {
      if (this.transaction.method === 'mpesa') {
        if (this.transaction.reference.length < 10) {
          notify.alert.info('Please enter valid MPESA reference', 2000)
          return;
        }
      }
      confirmDialog('Make Sale', this.makeSale, 'Are you sure you want to make this sale')
    },
    async makeSale() {
      let products = []
      this.cartProducts.forEach( item => {
        let total = item.price * item.quantity
        let product = {
          product_id: item.product_id,
          quantity: item.quantity,
          tax: item.product.tax_category.rate/100 * total,
          price: item.price
        }
        products.push(product)
      })
      let total = this.calculateTotal - this.discount
      if (this.transaction.amount > total) this.transaction.amount = total
      let allData = {
        organization_id: this.store.organization_id,
        store_id: this.store.id,
        user_id: this.profile.id,
        discount: this.discount,
        total: total,
        tax: this.calculateTax,
        products: products,
        transaction: this.transaction
      }
      let response = await saleService.createSale(allData)
      if (response) {
        sessionStorage.removeItem('cart')
        window.location.reload()
      }
    },
    async logout() {
      await authService.userLogout()
    }
  },

  computed: {
    productData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].product_id,
          this.dataTable[i].product.sku,
          this.dataTable[i].product.code,
          this.dataTable[i].product.name,
          formatNumber(this.dataTable[i].price),
        ];
        data.push(fields);
      }
      return data;
    },
    calculateTotal() {
      let total = 0
      for (let i = 0; i < this.cartProducts.length; i++) {
        total += this.cartProducts[i].price * this.cartProducts[i].quantity
      }
      this.transaction.amount = total - this.discount
      return total
    },
    calculateTax() {
      let tax = 0
      let total = 0
      for (let i = 0; i < this.cartProducts.length; i++) {
        total = this.cartProducts[i].price * this.cartProducts[i].quantity
        tax += this.cartProducts[i].product.tax_category.rate/100 * total
      }
      return tax
    },
    cartAvailable() {
      return this.cartProducts.length < 1
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.getCartProducts()
    await this.getStoreProducts()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

  watch: {
    dataTableSearch: function () {
      this.dataTableShow = this.dataTableSearch !== '';
    },
    'transaction.reference': function () {
      this.transaction.reference = this.transaction.reference.toUpperCase()
    },
    'transaction.method': function () {
      if (this.transaction.method === 'cash') {
        this.transaction.reference = ''
      }
    }
  }

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
*{margin: 0;padding: 0;box-sizing: border-box;list-style: none;font-family: 'Montserrat', sans-serif}
body{background-color: #b064f7;line-height: 1rem;font-size: 14px;padding: 10px}
nav{border-top-left-radius: 25px;border-top-right-radius: 25px;background-color: white}
.ribbon{width: 150px;height: 150px;overflow: hidden;position: absolute}
.ribbon::before, .ribbon::after{position: absolute;content: '';display: block;border: 5px solid red}
.ribbon span{position: absolute;display: block;width: 225px;padding: 15px 0;background-color: red;box-shadow: 0 5px 10px rgba(0, 0, 0, .1);color: #fff;font: 700 18px/1 'Lato', sans-serif;text-shadow: 0 1px 1px rgba(0, 0, 0, .2);text-transform: uppercase;text-align: center}
.ribbon-top-right{top: -12px;right: -12px}.ribbon-top-right::before, .ribbon-top-right::after{border-top-color: transparent;border-right-color: transparent}.ribbon-top-right::before{top: 0;left: 0}
.ribbon-top-right::after{bottom: 0;right: 0}
.ribbon-top-right span{left: -25px;top: 30px;transform: rotate(45deg)}
small{font-size: 12px}
td{vertical-align: middle}
.red{color: #fd1c1c;font-weight: 600}
.b-bottom{border-bottom: 2px dotted black;padding-bottom: 20px}
p{margin: 0px}
table input{width: 40px;border: 1px solid #eee}
input:focus{border: 1px solid #eee;outline: none}
.payment-summary .unregistered{width: 100%;display: flex;align-items: center;justify-content: center;background-color: #eee;text-transform: uppercase;font-size: 14px}
.payment-summary input{width: 100%;margin-right: 20px}
.payment-summary .sale{width: 100%;background-color: #e9b3b3;text-transform: uppercase;font-size: 12px;display: flex;justify-content: center;align-items: center;padding: 5PX 0}
.red{color: #fd1c1c}
.option input{display: none}
.option input:checked~.checkmark:after{display: block}
.option:hover input[type="radio"]~.checkmark{background-color: #f4f4f4}
.option input[type="radio"]:checked~.checkmark{background: #ac1f32;color: #fff;transition: 300ms ease-in-out 0s}
.option input[type="radio"]:checked~.checkmark:after{transform: translate(-50%, -50%) scale(1);color: #fff}
.quick-link:hover {opacity: 70%;cursor:pointer}
.nav:hover {cursor: pointer;opacity: 0.5;}
</style>
