<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-4">
          <span class="h4 text-uppercase">Purchase</span><br>
          <span class="h5 text-uppercase">{{ purchase.supplier.code }} - {{ purchase.supplier.name }}</span><br>
          <span class="h6">Created By: {{ purchase.user.first_name }} {{ purchase.user.last_name }}</span><br>
          <span class="h6">Created At: {{ formatDate(purchase.created_at) }}</span><br>
          <span :class="`badge bg-${getPurchaseStatus(purchase.status)}`">
            {{ purchase.status }}
          </span>
        </div>
        <Card v-if="purchase.status === 'pending'" body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              {{ purchase.supplier.name }} Products ({{productData.length}})
              <UInput v-model="dataTableSearch" size="md" placeholder="Search product" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="productData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row}">
                <Cell
                    v-for="(cellData, key) in row"
                    :class="row[10] === true ? 'bg-info-light' : ''"
                    @click="populateAddProduct(row)"
                    :cell-classes="[
                        // Center
                        ['selected', 'status',].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'reorder' || key === 8" pill :type="getPillByRepurchase(cellData)">
                    {{cellData}}
                  </Badge>
                  <Badge v-else-if="key.toString().toLowerCase() === 'status' || key === 9" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else-if="key === 10"></span>
                  <span v-else>{{cellData}}</span>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              Purchased Products ({{purchaseProducts.length}})
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-sm text-nowrap">
              <thead>
              <tr>
                <th scope="col">SKU</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Qty</th>
                <th scope="col">Buying Price</th>
                <th scope="col">Total</th>
                <th scope="col">Comment</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-if="purchaseProducts.length === 0" class="text-center fst-italic text-muted">No products in purchase purchase.</tr>
              <tr v-for="purchaseProduct in purchaseProducts" :key="purchaseProduct.id" style="font-size: 14px">
                <td>{{ purchaseProduct.product.sku }}</td>
                <td>{{ purchaseProduct.product.code }}</td>
                <td>{{ purchaseProduct.product.name }}</td>
                <td>{{ purchaseProduct.quantity }} {{ purchaseProduct.product.unit.name }}</td>
                <td>{{ formatNumber(purchaseProduct.product.buy) }}</td>
                <td>{{ formatNumber(purchaseProduct.product.buy * purchaseProduct.quantity) }}</td>
                <td>{{ purchaseProduct.comment }}</td>
                <td v-if="purchase.status === 'pending'">
                  <button
                      class="btn btn-primary btn-sm me-2"
                      @click="addProductModal = true; addProductMode = 'edit'; populatePurchaseEdit(purchaseProduct)"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                      class="btn btn-danger btn-sm"
                      @click="deletePurchaseProduct(purchaseProduct.id)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr class="fw-bold">
                <td colspan="5">Total:</td>
                <td>{{ formatNumber(calculateTotals) }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button
                v-if="purchase.status === 'pending'"
                class="btn btn-success text-uppercase"
                :class="purchaseProducts.length === 0 ? 'disabled' : ''"
                @click="confirmDialog(
                    'Complete Purchase',
                    submitPurchase,
                    'Are you sure you want to complete this purchase')">Submit Purchase
            </button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Purchase Modal -->
    <Modal :open="addProductModal" @closed="addProductModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="addProductMode === 'add'">Add Product to Purchased</h5>
        <h5 class="modal-title m-0" v-else>Edit Purchased Product</h5>
        <button @click="addProductModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" :value="selectedProduct.name" disabled>
            </div>
            <div class="form-group col-md-6">
              <label for="quantity" class="form-label fw-bold h5 mt-3">Quantity:</label>
              <input type="number" class="form-control form-control-lg" v-model="purchaseDetails.quantity">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="addProductModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="addProductMode === 'add'" @click="addPurchaseProduct">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updatePurchaseProduct(purchaseDetails.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Purchase Modal End -->

  </div>
</template>

<script>
import formatNumber from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";
import purchaseService from "@/api/purchaseService";
import formatDate from "@/utils/formatDate";
import confirmDialog  from "@/utils/confirmDialog";
import notify from "@/utils/notify";
import supplierService from "@/api/supplierService";

export default {
  name: "PurchaseDetails",

  data(){
    return {
      profile: {},
      store: {},
      purchase: {
        supplier: {},
        user: {},
      },
      purchaseDetails: {
        store_purchase_id: this.$route.params.id,
        product_id: '',
        quantity: ''
      },
      selectedProduct: {
        product: {},
      },
      purchaseProducts: [],
      loading: true,
      addProductModal: false,
      addProductMode: 'add',
      dataTableHeaders: [
        '#',
        'SKU',
        'Code',
        'Name',
        'Buying Price',
        'Selling Price',
        'Stock',
        'Reorder',
        'Reorder Status',
        'Status',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 5,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    formatDate,
    formatNumber,
    confirmDialog,
    getPillByRepurchase(cellData) {
      switch (cellData) {
        case 'reorder':
          return 'danger';
        case 'good':
          return 'success';
      }
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
      }
    },
    getPurchaseStatus(status) {
      switch (status) {
        case 'pending':
          return 'warning';
        case 'complete':
          return 'success';
        case 'submitted':
          return 'primary';
      }
    },
    async getSupplierProducts() {
      this.dataTable = await supplierService.getProductsBySupplier(this.purchase.supplier.id)
      this.loading = false
    },
    async getPurchaseDetails() {
      this.purchaseProducts = await purchaseService.getPurchaseDetails(this.$route.params.id)
    },
    populateAddProduct(product) {
      if (product[10] !== true) {
        this.addProductModal = true;
        this.selectedProduct = this.getSelectedProduct(product[0])
        this.purchaseDetails.product_id = this.selectedProduct.id
        this.purchaseDetails.price = this.selectedProduct.buy
      } else {
        notify.alert.info('Product already added', 2000)
      }
    },
    async addPurchaseProduct() {
      let response = await purchaseService.createPurchaseDetails(this.purchaseDetails)
      if (response) {
        this.addProductModal = false
        await this.getPurchaseDetails()
      }
    },
    async updatePurchaseProduct() {
      let response = await purchaseService.updatePurchaseDetails(this.purchaseDetails, this.purchaseDetails.id)
      if (response) {
        this.addProductModal = false
        await this.getPurchaseDetails()
      }
    },
    populatePurchaseEdit(purchase) {
      this.purchaseDetails = purchase
      this.selectedProduct.name = purchase.product.name
    },
    async deletePurchaseProduct(id) {
      let response = await purchaseService.deletePurchaseDetails(id)
      if (response) {
        await this.getPurchaseDetails()
      }
    },
    async submitPurchase() {
      await purchaseService.changePurchaseStatus({ status: 'submitted' },this.$route.params.id)
    },
    getSelectedProduct(id) {
      let data = this.dataTable.filter(
          item => item.id.indexOf(id) > -1
      )
      return data[0]
    },
    checkIncludedProduct(id) {
      let data = this.purchaseProducts.filter(
          item => item.product_id.indexOf(id) > -1
      )
      return data.length !== 0
    },
  },

  computed: {
    productData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          this.dataTable[i].sku,
          this.dataTable[i].code,
          this.dataTable[i].name,
          formatNumber(this.dataTable[i].buy),
          formatNumber(this.dataTable[i].price),
          this.dataTable[i].stock + ' ' + this.dataTable[i].unit.name,
          this.dataTable[i].reorder + ' ' + this.dataTable[i].unit.name,
          this.dataTable[i].reorder < this.dataTable[i].stock ? 'good' : 'reorder',
          this.dataTable[i].status,
          this.checkIncludedProduct(this.dataTable[i].id),
        ];
        data.push(fields);
      }
      return data;
    },
    calculateTotals() {
      let total = 0
      for (let i = 0; i < this.purchaseProducts.length; i++) {
        total += this.purchaseProducts[i].quantity * this.purchaseProducts[i].product.sell
      }
      return total
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.purchase = await purchaseService.getPurchase(this.$route.params.id)
    await this.getPurchaseDetails()
    await this.getSupplierProducts()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

  watch: {
    addProductModal: function () {
      if (!this.addProductModal) {
        this.purchaseDetails = {
          purchase_id: this.$route.params.id
        }
      }
    }
  }

}
</script>

<style scoped>
td { cursor: pointer; }
</style>
