<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-4">
          <span class="h4 text-uppercase">Store Stock Take</span><br>
          <span class="h5 text-uppercase">{{ stockTake.store.code }}-{{ stockTake.store.name }}</span><br>
          <span class="h6">Created By: {{ stockTake.user.first_name }} {{ stockTake.user.last_name }}</span><br>
          <span class="h6">Created At: {{ formatDate(stockTake.created_at) }}</span><br>
          <span :class="`badge bg-${stockTake.status === 'pending' ? 'warning' : 'success'}`">
            {{ stockTake.status }}
          </span>
        </div>
        <Card v-if="stockTake.status === 'pending'" body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              {{ store.name }} Products ({{productData.length}})
              <UInput v-model="dataTableSearch" size="md" placeholder="Search product" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="productData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row}">
                <Cell
                    v-for="(cellData, key) in row"
                    :class="row[10] === true ? 'bg-info-light' : ''"
                    class="text-nowrap"
                    @click="populateAddProduct(row)"
                    :cell-classes="[
                        // Center
                        ['selected', 'status', 'reorder'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'reorder' || key === 8" pill :type="getPillByReorder(cellData)">
                    {{cellData}}
                  </Badge>
                  <Badge v-else-if="key.toString().toLowerCase() === 'status' || key === 9" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else-if="key === 10"></span>
                  <span v-else>{{cellData}}</span>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              Stock Take Products ({{stockTakeProducts.length}})
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-sm text-nowrap">
              <thead>
              <tr>
                <th scope="col">SKU</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Expected</th>
                <th scope="col">Available</th>
                <th scope="col">Diff.</th>
                <th scope="col">Comment</th>
                <th scope="col">Added on</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-if="stockTakeProducts.length === 0" class="text-center fst-italic text-muted">No stock take products.</tr>
              <tr v-for="stockTakeProduct in stockTakeProducts" :key="stockTakeProduct.id" style="font-size: 14px">
                <td>{{ stockTakeProduct.product.sku }}</td>
                <td>{{ stockTakeProduct.product.code }}</td>
                <td>{{ stockTakeProduct.product.name }}</td>
                <td>{{ stockTakeProduct.expected }} {{ stockTakeProduct.product.unit.name }}</td>
                <td>{{ stockTakeProduct.available }} {{ stockTakeProduct.product.unit.name }}</td>
                <td>
                  <span :class="`badge bg-${stockDiff(stockTakeProduct).status}`">
                    {{ stockDiff(stockTakeProduct).diff }} {{ stockTakeProduct.product.unit.name }}
                  </span>
                </td>
                <td>{{ stockTakeProduct.comment }}</td>
                <td>{{ formatDate(stockTakeProduct.created_at) }}</td>
                <td v-if="stockTake.status === 'pending'">
                  <button
                      class="btn btn-primary btn-sm me-2"
                      @click="addProductModal = true; addProductMode = 'edit'; populateStockTakeEdit(stockTakeProduct)"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                      class="btn btn-danger btn-sm"
                      @click="deleteStockTakeProduct(stockTakeProduct.id)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button
                v-if="stockTake.status === 'pending'"
                class="btn btn-success text-uppercase"
                :class="stockTakeProducts.length === 0 ? 'disabled' : ''"
                @click="confirmDialog('Complete Stock Take', submitStockTake, 'Are you sure you want to complete this stock take')">Submit Stock Take
            </button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add StockTake Modal -->
    <Modal :open="addProductModal" @closed="addProductModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="addProductMode === 'add'">Add Product to Stock Take</h5>
        <h5 class="modal-title m-0" v-else>Edit Stock Take Product</h5>
        <button @click="addProductModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" :value="selectedProduct.product.name" disabled>
            </div>
            <div class="form-group col-md-6">
              <label for="expected" class="form-label fw-bold h5 mt-3">Expected:</label>
              <input type="text" class="form-control form-control-lg" v-model="stockTakeDetails.expected" disabled>
            </div>
            <div class="form-group col-md-6">
              <label for="available" class="form-label fw-bold h5 mt-3">Available:</label>
              <input type="number" class="form-control form-control-lg" v-model="stockTakeDetails.available">
            </div>
            <div class="form-group col-md-6">
              <label for="comment" class="form-label fw-bold h5 mt-3">Comment: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" v-model="stockTakeDetails.comment">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="addProductModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="addProductMode === 'add'" @click="addStockTakeProduct">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateStockTakeProduct(stockTakeDetails.id)">Update</button>
      </div>
    </Modal>
    <!-- Add StockTake Modal End -->

  </div>
</template>

<script>
import formatNumber from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";
import stockTakeService from "@/api/stockTakeService";
import formatDate from "@/utils/formatDate";
import confirmDialog  from "@/utils/confirmDialog";
import storeStockService from "@/api/storeStockService";
import notify from "@/utils/notify";

export default {
  name: "StockTakeDetails",

  data(){
    return {
      profile: {},
      store: {},
      stockTake: {
        store: {},
        user: {},
      },
      stockTakeDetails: {
        stock_take_id: this.$route.params.id,
        product_id: '',
        expected: '',
        available: '',
      },
      selectedProduct: {
        product: {},
      },
      stockTakeProducts: [],
      loading: true,
      addProductModal: false,
      addProductMode: 'add',
      dataTableHeaders: [
        '#',
        'SKU',
        'Code',
        'Name',
        'Buying Price',
        'Selling Price',
        'Stock',
        'Reorder',
        'Reorder Status',
        'Status',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 5,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    formatDate,
    formatNumber,
    confirmDialog,
    getPillByReorder(cellData) {
      switch (cellData) {
        case 'reorder':
          return 'danger';
        case 'good':
          return 'success';
      }
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
      }
    },
    async getStoreProducts() {
      this.dataTable = await storeStockService.getStoreStock(this.store.id)
      this.loading = false
    },
    async getStockTakeDetails() {
      this.stockTakeProducts = await stockTakeService.getStockTakeDetails(this.$route.params.id)
    },
    populateAddProduct(product) {
      if (product[10] !== true) {
        this.addProductModal = true
        this.selectedProduct = this.getSelectedProduct(product[0])
        this.stockTakeDetails.product_id = this.selectedProduct.product.id
        this.stockTakeDetails.expected = this.selectedProduct.stock
      } else {
        notify.alert.info('Product already added', 2000)
      }
    },
    async addStockTakeProduct() {
      let response = await stockTakeService.createStockTakeDetails(this.stockTakeDetails)
      if (response) {
        this.addProductModal = false
        await this.getStockTakeDetails()
      }
    },
    async updateStockTakeProduct() {
      let response = await stockTakeService.updateStockTakeDetails(this.stockTakeDetails, this.stockTakeDetails.id)
      if (response) {
        this.addProductModal = false
        await this.getStockTakeDetails()
      }
    },
    populateStockTakeEdit(stockTake) {
      this.stockTakeDetails = stockTake
      this.selectedProduct[3] = stockTake.product.name
    },
    async deleteStockTakeProduct(id) {
      let response = await stockTakeService.deleteStockTakeDetails(id)
      if (response) {
        await this.getStockTakeDetails()
      }
    },
    getSelectedProduct(id) {
      let data = this.dataTable.filter(
          item => item.product_id.indexOf(id) > -1
      )
      return data[0]
    },
    async submitStockTake() {
      await stockTakeService.approveStockTake(this.$route.params.id)
    },
    stockDiff(stockTakeProduct) {
      let diff = stockTakeProduct.available - stockTakeProduct.expected
      let status = 'success'
      if (diff < 0) {
        status = 'danger'
      } else if (diff > 0) {
        status = 'warning'
      }
      return {diff, status}
    },
    checkIncludedProduct(id) {
      let data = this.stockTakeProducts.filter(
          item => item.product_id.indexOf(id) > -1
      )
      return data.length !== 0
    },
  },

  computed: {
    productData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].product_id,
          this.dataTable[i].product.sku,
          this.dataTable[i].product.code,
          this.dataTable[i].product.name,
          formatNumber(this.dataTable[i].product.buy),
          formatNumber(this.dataTable[i].price),
          this.dataTable[i].stock + ' ' + this.dataTable[i].product.unit.name,
          this.dataTable[i].reorder + ' ' + this.dataTable[i].product.unit.name,
          this.dataTable[i].reorder < this.dataTable[i].stock ? 'good' : 'reorder',
          this.dataTable[i].product.status,
          this.checkIncludedProduct(this.dataTable[i].product_id),
        ];
        data.push(fields);
      }
      return data;
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.stockTake = await stockTakeService.getStockTake(this.$route.params.id)
    await this.getStockTakeDetails()
    await this.getStoreProducts()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

  watch: {
    addProductModal: function () {
      if (!this.addProductModal) {
        this.stockTakeDetails = {
          stock_take_id: this.$route.params.id
        }
      }
    }
  }

}
</script>

<style scoped>
td { cursor: pointer; }
</style>
