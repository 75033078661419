<template>
  <Card>
    <template slot="header">Supplier Details</template>
    <div class="row">
      <div v-if="loading" class="text-center">
        <Spinner spinner-class="mb-1" />
      </div>
      <div v-if="!loading" class="col-md-12">
        <h2 class="text-center">{{ supplier.name }}</h2>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div v-if="!loading" class="col-md-12 row">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Code</h6>
            <p class="fst-italic">{{ supplier.code }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Email</h6>
            <p class="fst-italic">{{ supplier.email }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Phone</h6>
            <p class="fst-italic">{{ supplier.phone }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Address</h6>
            <p class="fst-italic">{{ supplier.address }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Type</h6>
            <p class="fst-italic">{{ supplier.type }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Status</h6>
            <p class="fst-italic">
              <span :class="`badge bg-${supplier.status === 'active' ? 'success' : 'danger'}`">{{ supplier.status }}</span>
            </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Account Name</h6>
            <p class="fst-italic">{{ supplier.account_name }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Account No</h6>
            <p class="fst-italic">{{ supplier.account_no }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Bank Name</h6>
            <p class="fst-italic">{{ supplier.bank_name }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Branch Name</h6>
            <p class="fst-italic">{{ supplier.branch_name }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Bank Code</h6>
            <p class="fst-italic">{{ supplier.bank_code }}</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="mt-1 fw-bold">Branch Code</h6>
            <p class="fst-italic">{{ supplier.branch_code }}</p>
          </div>
        </div>
      </div>

      <hr class="border-1 border-info border-opacity-75 mt-5" />
      <div class="col-md-12 d-flex justify-content-center">
        <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
        <button
            v-if="profile.role !== 'user' && profile.role !== 'admin'"
            class="btn btn-primary text-uppercase me-4"
            @click="$router.push({path: '/edit-supplier/' + $route.params.id})"
        >Edit</button>
        <button
            v-if="profile.role !== 'user' && profile.role !== 'admin'"
            class="btn btn-success text-uppercase"
            @click="$router.push({path: '/supplier/' + $route.params.id + '/products'})"
        >Products</button>
      </div>
    </div>
  </Card>
</template>

<script>
import supplierService from "@/api/supplierService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";
import formatNumber from "../../utils/formatNumber";

export default {
  name: "SupplierDetails",

  data() {
    return {
      profile: {},
      loading: true,
      showModal: false,
      supplier: {},
      imagesUrl: process.env.VUE_APP_API_IMAGE_URL + 'supplier/',
    }
  },

  methods: {
    formatNumber,
    formatDate,
    async getSupplier() {
      this.supplier = await supplierService.getSupplier(this.$route.params.id)
      this.loading = false
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getSupplier()
  },

}
</script>
