'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
  async getTaxCategories(page) {
    const data = {
      method: 'get',
      url: 'tax-categories',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async updateTaxCategory(json, id) {
    const data = {
      method: 'put',
      url: 'tax-category/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('TaxCategory updated successfully')
      return true;
    }
  },
}
