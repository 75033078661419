'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
  async getPurchases() {
    const data = {
      method: 'get',
      url: 'purchases',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getPurchase(id) {
    const data = {
      method: 'get',
      url: 'purchase/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getPurchaseDetails(id) {
    const data = {
      method: 'get',
      url: 'purchase/' + id + '/details',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createPurchaseDetails(json) {
    const data = {
      method: 'post',
      url: 'purchase-details',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Purchase product added', 2000)
      return true;
    }
  },
  async updatePurchaseDetails(json, id) {
    const data = {
      method: 'put',
      url: 'purchase-details/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Purchase product updated', 2000)
      return true;
    }
  },
  async deletePurchaseDetails(id) {
    const data = {
      method: 'delete',
      url: 'purchase-details/' + id
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Purchase product deleted', 2000)
      return true;
    }
  },
  async changePurchaseStatus(body, id) {
    const data = {
      method: 'patch',
      url: 'purchase-status/' + id,
      body: body,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Purchase submitted successfully')
      window.location.reload();
    }
  },
}
