'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
  async getCategories() {
    const data = {
      method: 'get',
      url: 'categories',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getCategory(id) {
    const data = {
      method: 'get',
      url: 'category/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createCategory(json) {
    const data = {
      method: 'post',
      url: 'category',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Category created successfully')
      return true;
    }
  },
  async updateCategory(json, id) {
    const data = {
      method: 'put',
      url: 'category/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Category updated successfully')
      return true;
    }
  },
  async getProductsByCategory(id) {
    const data = {
      method: 'get',
      url: 'category/' + id + '/products',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
}
