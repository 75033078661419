function Ksh(number, symbol = true) {
  let options = {
    minimumFractionDigits: 2
  }
  if (symbol) {
    options.currency = 'KES'
    options.style = 'currency'
  }
  let Ksh = new Intl.NumberFormat('en-US', options);
  number = number > 0 ? number : 0
  return Ksh.format(number)
}

export default Ksh
