<template>
  <div>
    <div class="row">
      <div class="mt-1">
        <Card body-classes="p-0">
          <template #header>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <span>Sales Summary</span>
              <select data="" type="text" class="form-select form-select mb-0 w-25" v-model="summaryDuration">
                <option v-for="year in analytics.years" :key="year" :value="year">Year {{ year }}</option>
              </select>
            </div>
          </template>
          <div>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
            <div v-else class="row">
              <div class="row col-xxl-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="col-xxl-12 col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3" v-for="item in infoCols">
                  <Card>
                    <template #default>
                      <div class="row align-items-center align-content-center">
                        <div class="col-5 justify-content-center">
                          <div
                              :class="[`bg-${item.bg}-light rounded p-4 text-${item.bg}`]"
                              class="text-center"
                          >
                            <vue-feather :type="item.icon" :stroke="item.color" size="50" stroke-width="2"></vue-feather>
                          </div>
                        </div>
                        <div class="col-7">
                          <div class="font-size-125 fw-bold">{{ item.title }}</div>
                          <div class="font-size-100 color-gray-600">
                            Data to be added
                          </div>
                          <div :class="[`mt-3 text-${item.bg} font-size-150 fw-bold`]">
                            {{ item.value }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
              <div class="col-xxl-9 col-xl-12 col-lg-12">
                <LineChart
                    :data="this.analytics.store.datasets"
                    :labels="this.analytics.store.labels"
                ></LineChart>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import {decrypt} from "@/utils/crypto";
import analyticsService from "@/api/analyticsService";
import LineChart from "@/components/charts/LineChart.vue";
import formatNumber from "@/utils/formatNumber";

export default {
  name: "StoreAnalytics",
  components: {LineChart},

  data(){
    return {
      profile: {},
      store: {},
      loading: true,
      analytics: {
        years: [],
        store: {
          labels: [],
          datasets: []
        },
        sales: {}
      },
      summaryDuration: new Date().getFullYear(),
    }
  },

  methods: {
    async getAnalytics() {
      this.loading = true
      this.analytics = await analyticsService.getStoreAnalytics(this.store.id, this.summaryDuration)
      this.loading = false
    }
  },

  computed: {
    infoCols() {
      return [
        {
          title:'Sales (' + this.analytics.sales.count + ')',
          value:formatNumber(this.analytics.sales.total, false),
          icon:"dollar-sign",
          color:"darkviolet",
          bg:'primary',
        },
        {
          title:'Tax',
          value:formatNumber(this.analytics.sales.tax, false),
          icon:"pie-chart",
          color:"crimson",
          bg:'secondary',
        },
        {
          title:'Discount',
          value:formatNumber(this.analytics.sales.discount, false),
          icon:"percent",
          color:"gray",
          bg:'default',
        },
      ]
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    await this.getAnalytics()
  },

  watch: {
    summaryDuration: async function () {
      await this.getAnalytics()
    }
  },

}
</script>

<style scoped>

</style>
