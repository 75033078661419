'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
  async getTransfers() {
    const data = {
      method: 'get',
      url: 'transfers',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createTransfer(json) {
    const data = {
      method: 'post',
      url: 'transfer',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Transfer created successfully')
      return true;
    }
  },
  async getTransfer(id) {
    const data = {
      method: 'get',
      url: 'transfer/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async updateTransfer(json, id) {
    const data = {
      method: 'put',
      url: 'transfer/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Transfer updated successfully')
      return true;
    }
  },
  async getTransferNextNo() {
    const data = {
      method: 'get',
      url: 'transferNextNo',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getTransferDetails(id) {
    const data = {
      method: 'get',
      url: 'transfer/' + id + '/details',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createTransferDetails(json) {
    const data = {
      method: 'post',
      url: 'transfer-details',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Transfer product added', 2000)
      return true;
    }
  },
  async updateTransferDetails(json, id) {
    const data = {
      method: 'put',
      url: 'transfer-details/' + id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Transfer product updated', 2000)
      return true;
    }
  },
  async deleteTransferDetails(id) {
    const data = {
      method: 'delete',
      url: 'transfer-details/' + id
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Transfer product deleted', 2000)
      return true;
    }
  },
  async approveTransfer(id) {
    const data = {
      method: 'patch',
      url: 'transfer-approve/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Transfer approved successfully')
      window.location.reload();
    }
  },
}
