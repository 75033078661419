<template>
  <div>
    <div v-if="stores.length > 0" class="row">
      <div class="col-md-12 mb-2">
        <div class="col-md-12 d-flex justify-content-between">
          <vue-feather class="nav" type="arrow-left-circle" @click="$router.go(-1)" stroke="#000000" size="35" stroke-width="1"></vue-feather>
          <vue-feather class="nav" type="log-out" @click="logout" stroke="#fc0303" size="35" stroke-width="1"></vue-feather>
        </div>
        <h4 class="text-uppercase text-center fw-bold mt-3">Select Store</h4>
        <hr class="border-1 border-info border-opacity-75 mt-3" />
      </div>
      <div v-if="loading" class="text-center">
        <Spinner spinner-class="mb-1" />
      </div>
      <div v-else class="col-xxl-3 col-xl-4 col-md-6" v-for="store in stores">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              {{store.name}}
            </div>
          </template>
          <div class="text-center">
            <span :class="`badge bg-${store.type === 'main' ? 'success' : 'info'} text-uppercase`">{{ store.type }}</span><br>
            {{ store.code }}<br>
            {{ store.address }}
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button v-if="!checkActiveStore(store.id)" class="btn btn-dark text-uppercase" @click="selectStore(store)">Select</button>
            <button v-else class="btn btn-outline-dark text-uppercase" disabled>Current</button>
          </div>
        </Card>
      </div>
    </div>

    <div v-else class="row d-flex align-items-center justify-content-center">
      <div class="col-xxl-6 col-xl-8 col-md-10">
        <Card body-classes="p-0" class="mb-5 bg-white">
          <template v-slot:header>
            Register Store
          </template>
          <div class="col-md-12">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Code" v-model="store.code" readonly>
                </div>
                <div class="form-group col-md-6">
                  <label for="name" class="form-label fw-bold h5 mt-3">Branch Name:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Branch Name" v-model="store.name">
                </div>
                <div class="form-group col-md-6">
                  <label for="address" class="form-label fw-bold h5 mt-3">Address:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Address" v-model="store.address">
                </div>
                <div class="form-group col-md-6">
                  <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="store.phone">
                </div>
              </div>
            </form>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-4" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button v-if="profile.role !== 'user'" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import storeService from "@/api/storeService";
import formatDate from "../../utils/formatDate";
import {decrypt, encrypt} from "@/utils/crypto";
import notify from "@/utils/notify";
import authService from "@/api/authService";

export default {
  name: "SelectStore",

  data(){
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    return {
      organization: {},
      profile: {},
      loading: true,
      stores: [],
      store: {
        organization_id: this.organization.id,
        type: 'main',
        online: 0,
      },
    }
  },

  methods: {
    formatDate,
    getStores: async function () {
      this.stores = await storeService.getStores()
      this.loading = false
    },
    async submitForm() {
      let response = await storeService.createStore(this.store)
      if (response) {
        window.location.reload()
      }
    },
    selectStore(store) {
      sessionStorage.setItem('store', encrypt(JSON.stringify(store)))
      notify.alert.success('Switched to ' + store.name + ' successfully')
      this.$router.push('/dashboard')
    },
    checkActiveStore(storeId) {
      let storeData = sessionStorage.getItem('store')
      if (storeData !== null) {
        let store = JSON.parse(decrypt(storeData))
        if (store.id === storeId) {
          return true
        }
      }
    },
    async logout() {
      await authService.userLogout()
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    let nextNo = await storeService.getStoreNextNo()
    this.store.code = nextNo.next
    await this.getStores()
  },

}
</script>

<style scoped>
.nav:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>
