<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Stores ({{stores.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'store'" class="btn btn-success btn-sm" @click="storeModal = true; storeMode = 'add'">+ Add Store</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Phone</th>
                <th scope="col">Type</th>
                <th scope="col">Online</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="store in stores">
                <td>{{ store.code }}</td>
                <td>{{ store.name }}</td>
                <td>{{ store.address }}</td>
                <td>{{ store.phone }}</td>
                <td>{{ store.type }}</td>
                <td><badge :type="getPillByStatus(store.online)">{{ store.online ? 'Online' : 'Offline' }}</badge></td>
                <td>{{ formatDate(store.created_at) }}</td>
                <td>
                  <button
                      v-if="profile.role !== 'store'"
                      class="btn btn-primary btn-sm"
                      @click="storeModal = true; storeMode = 'edit'; populateStoreEdit(store)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Store Modal -->
    <Modal :open="storeModal" @closed="storeModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="storeMode === 'add'">Add Store</h5>
        <h5 class="modal-title m-0" v-else>Edit Store</h5>
        <button @click="storeModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Code" v-model="store.code" readonly>
            </div>
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Branch Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Branch Name" v-model="store.name">
            </div>
            <div class="form-group col-md-6">
              <label for="address" class="form-label fw-bold h5 mt-3">Address:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Address" v-model="store.address">
            </div>
            <div class="form-group col-md-6">
              <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="store.phone">
            </div>
            <div v-if="store.role !== 'primary'" class="form-group col-md-6">
              <label for="online" class="form-label fw-bold h5 mt-3">Online Status:</label>
              <select data="" class="form-select form-select-lg" v-model="store.online">
                <option value="" disabled selected>Select online status</option>
                <option value="1">Online</option>
                <option value="0">Offline</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="storeModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="storeMode === 'add'" @click="addStore">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateStore(store)">Update</button>
      </div>
    </Modal>
    <!-- Add Store Modal End -->

  </div>
</template>

<script>
import storeService from "@/api/storeService";
import formatDate from "../../utils/formatDate";
import {decrypt, encrypt} from "@/utils/crypto";

export default {
  name: "Stores",

  data(){
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    return {
      profile: {},
      organization: {},
      currentStore: {},
      loading: true,
      stores: [],
      storeMode: 'add',
      storeModal: false,
      store: {
        organization_id: this.organization.id,
        code: '',
        name: '',
        address: '',
        phone: '',
        online: 0,
      },
    }
  },

  methods: {
    formatDate,
    getStores: async function () {
      this.stores = await storeService.getStores();
      this.loading = false
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 0:
          return 'danger';
        case 1:
          return 'success';
      }
    },
    populateStoreEdit(store) {
      this.store = store
    },
    async addStore() {
      let response = await storeService.createStore(this.store)
      if (response) {
        this.storeModal = false
        await this.getStores()
      }
    },
    async updateStore(store) {
      let response = await storeService.updateStore(this.store, store.id)
      if (response) {
        if (this.currentStore.id === store.id) {
          sessionStorage.setItem('store', encrypt(JSON.stringify(store)))
        }
        this.storeModal = false
        await this.getStores()
      }
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.currentStore = JSON.parse(decrypt(sessionStorage.getItem('store')))
    let nextNo = await storeService.getStoreNextNo()
    this.store.code = nextNo.next
    await this.getStores()
  },

  watch: {
    storeModal: function () {
      if (!this.storeModal) {
        this.store = {}
      }
    },
  }

}
</script>

<style scoped>

</style>
