<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Transactions ({{transactionsData.length}})
              </span>
              <UInput v-model="dataTableSearch" size="md" placeholder="Search transactions" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="transactionsData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row, rowId}">
                <Cell
                    v-for="(cellData, key) in row"
                    class="text-nowrap"
                    :cell-classes="[
                        // Center
                        ['selected', 'status'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <span v-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else>{{cellData}}</span>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import transactionService from "@/api/transactionService";
import formatDate from "../../utils/formatDate";
import formatNumber from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Transactions",

  data(){
    return {
      profile: {},
      loading: true,
      dataTableHeaders: [
        '#',
        'Amount',
        'Method',
        'Reference',
        'Date'
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 10,
    }
  },

  methods: {
    formatDate,
    formatNumber,
    getTransactions: async function () {
      this.dataTable = await transactionService.getTransactions();
      this.loading = false
    },
  },

  computed: {
    transactionsData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          formatNumber(this.dataTable[i].amount),
          this.dataTable[i].method.toUpperCase(),
          this.dataTable[i].reference === null ? '' : this.dataTable[i].reference,
          formatDate(this.dataTable[i].created_at),
        ];
        data.push(fields);
      }
      return data;
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getTransactions()
  },

}
</script>

<style scoped>

</style>
