<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5 bg-white">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Edit Supplier
              </span>
            </div>
          </template>
          <div class="col-md-12">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Code" v-model="supplier.code">
                </div>
                <div class="form-group col-md-5">
                  <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Name" v-model="supplier.name">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Phone:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="supplier.phone">
                </div>
                <div class="form-group col-md-3">
                  <label for="name" class="form-label fw-bold h5 mt-3">Email:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Email" v-model="supplier.email">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Address:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Address" v-model="supplier.address">
                </div>
                <div class="form-group col-md-2">
                  <label for="status" class="form-label fw-bold h5 mt-3">Status:</label>
                  <select data="" class="form-select form-select-lg" v-model="supplier.status">
                    <option value="" disabled selected>Select status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="type" class="form-label fw-bold h5 mt-3">Type:</label>
                  <select data="" class="form-select form-select-lg" v-model="supplier.type">
                    <option value="" disabled selected>Select type</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Wholesaler">Wholesaler</option>
                  </select>
                </div>
              </div>
              <hr class="border-1 border-info border-opacity-75 mt-3" />
              <div class="row">

                <div class="form-group col-md-3">
                  <label for="name" class="form-label fw-bold h5 mt-3">Account Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="Account name" v-model="supplier.account_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="name" class="form-label fw-bold h5 mt-3">Bank Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="bankOptions" id="bankName" v-model="supplier.bank_name" placeholder="Type to search...">
                  <datalist id="bankOptions">
                    <option v-for="bank in bankNames.banks" :key="bank" :value="bank"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-3">
                  <label for="name" class="form-label fw-bold h5 mt-3">Branch Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="branchOptions" id="branchName" v-model="supplier.branch_name" placeholder="Type to search...">
                  <datalist id="branchOptions">
                    <option v-for="branch in bankBranches" :key="branch" :value="branch"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-3">
                  <label for="name" class="form-label fw-bold h5 mt-3">Account Number: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="Account number" v-model="supplier.account_no">
                </div>
              </div>
            </form>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-4" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button v-if="profile.role !== 'user'" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import supplierService from "@/api/supplierService";
import bankService from "@/api/bankService";
import {decrypt} from "@/utils/crypto";

export default {
  name: "EditSupplier",

  data() {
    return {
      profile: {},
      supplier: {},
      bankNames: {
        banks: [],
        branches: {}
      },
      bankBranches: []
    }
  },

  methods: {
    submitForm: async function () {
      await supplierService.updateSupplier(this.supplier, this.$route.params.id)
    },
  },

  async created() {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.supplier = await supplierService.getSupplier(this.$route.params.id)
    this.bankNames = await bankService.getBankNames()
  },

  watch: {
    'supplier.bank_name': function () {
      this.bankBranches = this.bankNames.branches[this.supplier.bank_name]
    },
    'supplier.name': function () {
      this.supplier.account_name = this.supplier.name
    }
  },
}
</script>

<style scoped>

</style>
