<template>
  <Line
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
  />
</template>

<script>
import 'chart.js/auto';
import {Line} from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "LineChart",
  components: {Line},

  props:{
    data:{
      type:Array,
      required:true,
    },
    labels:{
      type:Array,
      required:true,
    }
  },
  data(){
    let delayed;
    return {
      chartOptions: {
        responsive: true,
        animation: {
          onComplete: () => {
            delayed = true;
          },
          delay: (context) => {
            let delay = 0;
            if (context.type === 'data' && context.mode === 'default' && !delayed) {
              delay = context.dataIndex * 300 + context.datasetIndex * 100;
            }
            return delay;
          },
        },
      },
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.populateDataSet()
      }
    },
  },

  methods: {
    populateDataSet() {
      let results = [];
      this.data.forEach((element) => {
        let info = {
          label: element.label,
          data: element.data,
          backgroundColor: element.backgroundColor,
          borderColor: element.borderColor,
          borderWidth: 2
        }
        results.push(info)
      })
      return results
    }
  }
}
</script>
