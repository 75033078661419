'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
  async getAllSales() {
    const data = {
      method: 'get',
      url: 'sales',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getStoreSales(id) {
    const data = {
      method: 'get',
      url: 'sales/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async getSale(id) {
    const data = {
      method: 'get',
      url: 'sale/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createSale(json) {
    const data = {
      method: 'post',
      url: 'sale',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Sale added successfully', 2000)
      return true;
    }
  },
  async getSaleDetails(id) {
    const data = {
      method: 'get',
      url: 'sale/' + id + '/details',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
}
