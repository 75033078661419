<template>
  <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
  />
</template>

<script>
    import 'chart.js/auto';
    import {Bar} from "vue-chartjs";
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: "BarChart",
        components: {Bar},

        props:{
            data:{
                type:Array,
                required:true,
            },
            labels:{
                type:Array,
                required:true,
            }
        },
      data(){
          let delayed;
          return {
            chartOptions: {
              responsive: true,
              animation: {
                onComplete: () => {
                  delayed = true;
                },
                delay: (context) => {
                  let delay = 0;
                  if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 300 + context.datasetIndex * 100;
                  }
                  return delay;
                },
              },
            },
          }
      },
      computed: {
        chartData() {
          return {
            labels: this.labels,
            datasets: [
              {
                label: 'Monthly Sales',
                data: this.data,
                borderColor: '#1253ff',
                backgroundColor: '#e1e9fa',
                borderWidth: 2,
                borderRadius: 10,
                borderSkipped: false,
              },
            ]
          }
        }
      },
    }
</script>
