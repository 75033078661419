<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                {{ store.name }} Products ({{productData.length}})
              </span>
              <UInput v-model="dataTableSearch" size="md" placeholder="Search product" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="productData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row, rowId}">
                <Cell
                    v-for="(cellData, key) in row"
                    :cell-classes="[
                        // Center
                        ['selected', 'status', 'reorder'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'reorder' || key === 8" pill :type="getPillByReorder(cellData)">
                    {{cellData}}
                  </Badge>
                  <Badge v-else-if="key.toString().toLowerCase() === 'status' || key === 9" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else>{{cellData}}</span>
                </Cell>
                <Cell>
                  <Button class="btn-primary btn-sm" @click="populateStoreProduct(rowId)">Edit</Button>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <!-- Edit Store Product Modal -->
    <Modal :open="storeProductModal" @closed="storeProductModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0">Edit Store Product</h5>
        <button @click="storeProductModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-8">
              <label for="code" class="form-label fw-bold h5 mt-3">Product:</label>
              <input type="text" class="form-control form-control-lg" :value="selectedProduct.product.name" disabled>
            </div>
            <div class="form-group col-md-4">
              <label for="reorder" class="form-label fw-bold h5 mt-3">Reorder Level:</label>
              <input type="number" class="form-control form-control-lg" v-model="product.reorder">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="storeProductModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" @click="updateStoreProduct">Update</button>
      </div>
    </Modal>
    <!-- Edit Store Product Modal End -->

  </div>
</template>

<script>
import formatNumber from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";
import storeStockService from "@/api/storeStockService";

export default {
  name: "Stock",

  data(){
    return {
      profile: {},
      store: {},
      product: {},
      storeProductModal: false,
      selectedProduct: {},
      loading: true,
      dataTableHeaders: [
        '#',
        'SKU',
        'Code',
        'Name',
        'Buying Price',
        'Selling Price',
        'Stock',
        'Reorder',
        'Reorder Status',
        'Status',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 10,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    getPillByReorder(cellData) {
      switch (cellData) {
        case 'reorder':
          return 'danger';
        case 'good':
          return 'success';
      }
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
      }
    },
    async getStoreStock() {
      this.dataTable = await storeStockService.getStoreStock(this.store.id)
      this.loading = false
    },
    updateStoreProduct() {
      let response = storeStockService.updateStoreStock(this.product, this.selectedProduct.id)
      if (response) {
        this.storeProductModal = false
        this.getStoreStock()
      }
    },
    populateStoreProduct(id) {
      let data = this.dataTable.filter(
          item => item.id.indexOf(id) > -1
      )
      this.selectedProduct = data[0]
      this.product.reorder = this.selectedProduct.reorder
      this.storeProductModal = true
    }
  },

  computed: {
    productData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          this.dataTable[i].product.sku,
          this.dataTable[i].product.code,
          this.dataTable[i].product.name,
          formatNumber(this.dataTable[i].product.buy),
          formatNumber(this.dataTable[i].price),
          this.dataTable[i].stock + ' ' + this.dataTable[i].product.unit.name,
          this.dataTable[i].reorder + ' ' + this.dataTable[i].product.unit.name,
          this.dataTable[i].reorder < this.dataTable[i].stock ? 'good' : 'reorder',
          this.dataTable[i].product.status,
        ];
        data.push(fields);
      }
      return data;
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    await this.getStoreStock()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

}
</script>

<style scoped>

</style>
