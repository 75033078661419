<template>
  <Card>
    <template slot="header">Organization Details</template>
    <div class="row">
      <div v-if="loading" class="text-center">
        <Spinner spinner-class="mb-1" />
      </div>
      <div v-if="!loading" class="col-md-12">
        <h2 class="text-center">{{ organization.name }}</h2>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div v-if="!loading" class="col-md-12 row">
        <div class="col-md-4 col-lg-3">
          <img class="img-thumbnail bg-dark-subtle" width="190" :src="logoUrl + organization.logo" alt="">
          <br>
          <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-primary mt-3 text-uppercase" @click="$refs.refInputEl.click()">Upload New Logo</button>
          <p class="text-body-1 mt-3">
            Allowed JPEG, JPG, or PNG.<br>
            Max size of 2MB
          </p>
          <input
              ref="refInputEl"
              type="file"
              name="logo"
              accept=".jpeg,.png,.jpg"
              hidden
              @input="changeLogo"
          >
        </div>
        <div class="col-md-8 col-lg-9 row">
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">Address</h6>
            <p class="fst-italic">{{ organization.address }}</p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">Phone</h6>
            <p class="fst-italic">{{ organization.phone }}</p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">Email address</h6>
            <p class="fst-italic">{{ organization.email }}</p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">Website</h6>
            <p class="fst-italic">{{ organization.website }}</p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">PIN No.</h6>
            <p class="fst-italic">{{ organization.pin_no }}</p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">Bank name</h6>
            <p class="fst-italic">{{ organization.bank_name }}</p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">Account number</h6>
            <p class="fst-italic">{{ organization.account_no }}</p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h6 class="mt-1 fw-bold">Branch name</h6>
            <p class="fst-italic">{{ organization.branch_name }}</p>
          </div>
        </div>
      </div>

<!--      <hr class="border-1 border-info border-opacity-75 mt-3" />-->

<!--      <div v-if="!loading" class="col-md-12 row">-->
<!--        <div class="col-md-6 col-lg-3">-->
<!--          <h6 class="mt-1 fw-bold">Subscription</h6>-->
<!--          <p class="fst-italic">{{ subscription.package.name }}</p>-->
<!--        </div>-->
<!--        <div class="col-md-6 col-lg-3">-->
<!--          <h6 class="mt-1 fw-bold">Employees</h6>-->
<!--          <p class="fst-italic">{{ subscription.package.employees === 1000 ? 'Unlimited' : subscription.package.employees }}</p>-->
<!--        </div>-->
<!--        <div class="col-md-6 col-lg-3">-->
<!--          <h6 class="mt-1 fw-bold">Price</h6>-->
<!--          <p class="fst-italic">{{ formatNumber(subscription.package.price) + '/month' }}</p>-->
<!--        </div>-->
<!--        <div class="col-md-6 col-lg-3">-->
<!--          <h6 class="mt-1 fw-bold">Period</h6>-->
<!--          <p class="fst-italic">{{ formatDate(subscription.from) }} - {{ formatDate(subscription.to) }}</p>-->
<!--        </div>-->
<!--      </div>-->

      <hr class="border-1 border-info border-opacity-75 mt-5" />
      <div class="col-md-12 d-flex justify-content-center">
        <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
        <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-primary text-uppercase" @click="showModal = true">Edit</button>
      </div>
    </div>

    <!-- Edit Organization Modal -->
    <Modal :open="showModal" @closed="showModal = false" dialog-size="modal-xl">
      <div class="modal-header">
        <h5 class="modal-title m-0">Edit Organization</h5>
        <button @click="showModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" v-model="organization.name">
            </div>
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Address:</label>
              <input type="text" class="form-control form-control-lg" v-model="organization.address">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Phone:</label>
              <input type="text" class="form-control form-control-lg" v-model="organization.phone">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Email:</label>
              <input type="text" class="form-control form-control-lg" v-model="organization.email">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">PIN No:</label>
              <input type="text" class="form-control form-control-lg" v-model="organization.pin_no">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Website: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" v-model="organization.website">
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-5" />
          <div class="row">
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Bank Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" list="bankOptions" id="bankName" v-model="organization.bank_name" placeholder="Type to search...">
              <datalist id="bankOptions">
                <option v-for="bank in bankNames.banks" :key="bank" :value="bank"></option>
              </datalist>
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Branch Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" list="branchOptions" id="branchName" v-model="organization.branch_name" placeholder="Type to search...">
              <datalist id="branchOptions">
                <option v-for="branch in bankBranches" :key="branch" :value="branch"></option>
              </datalist>
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Account Number: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" placeholder="Account number" v-model="organization.account_no">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="showModal = false">Cancel</button>
        <button v-if="profile.role !== 'user' && profile.role !== 'admin'" type="button" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
      </div>
    </Modal>
    <!-- Edit Organization Modal End -->

  </Card>
</template>

<script>
import organizationService from "@/api/organizationService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";
import formatNumber from "../../utils/formatNumber";
import bankService from "@/api/bankService";

export default {
  name: "Organization",

  data() {
    return {
      profile: {},
      loading: true,
      showModal: false,
      organization: {},
      subscription: {
        package: {}
      },
      bankNames: {
        banks: [],
        branches: {}
      },
      bankBranches: [],
      logoUrl: process.env.VUE_APP_API_IMAGE_URL + 'organization/',
    }
  },

  methods: {
    formatNumber,
    formatDate,
    async getOrganization() {
      this.organization = await organizationService.getOrganization()
      this.loading = false
    },
    submitForm: async function () {
      let response = await organizationService.updateOrganization(this.organization)
      if (response) {
        this.showModal = false
        await this.getOrganization()
      }
    },
    changeLogo : async function(file) {
      const fileReader = new FileReader()
      const { files } = file.target
      let response = false
      if (files && files.length) {
        fileReader.readAsDataURL(files[0])
        fileReader.onload = async () => {
          if (typeof fileReader.result === 'string')
            response = await organizationService.updateOrganizationLogo({ file: fileReader.result })
            if (response)
              await this.getOrganization()
        }
      }
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getOrganization()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
    this.bankNames = await bankService.getBankNames()
    if (this.organization.bank_name !== null) {
      this.bankBranches = this.bankNames.branches[this.organization.bank_name]
    }
  },

  watch: {
    'organization.bank_name': function () {
      this.bankBranches = this.bankNames.branches[this.organization.bank_name]
    }
  }
}
</script>
