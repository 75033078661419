<template>
    <div>
      <div class="row">
        <div v-if="subscriptionStatus !== 'active'" role="alert" :class="subscriptionStatus === 'expiring' ? 'alert alert-info' : 'alert alert-danger'">
          <div class="content text-center">
            Your subscription {{ subscriptionStatus }} on {{ formatDate(subscription.to) }}
            <button v-if="subscription.package.name === 'Trial'" class="btn btn-sm btn-dark text-uppercase ms-3" @click="$router.push('upgrade')">Upgrade</button>
            <button v-else class="btn btn-sm btn-dark text-uppercase ms-3" @click="subscriptionModal = true">Renew</button>
          </div>
        </div>
        <div v-if="subscription.package.employees <= employeesNo" role="alert" class="alert alert-info">
          <div class="content text-center">
            Your subscription has reached the limit of {{ subscription.package.employees }} employees.
            <button class="btn btn-sm btn-dark text-uppercase ms-3" @click="$router.push('upgrade')">Upgrade</button>
          </div>
        </div>
        <div v-if="neededFiles?.length" role="alert" class="alert alert-info">
          <div class="content">
            You are required to upload the following files to complete your registration:
            <span class="btn btn-sm btn-dark text-uppercase ms-3" @click="filesModal = true">Upload</span>
            <ul><li v-for="file in neededFiles" class="fw-bold" :key="file">{{ file }}</li></ul>
          </div>
        </div>
        <div class="row mb-3">
          <h3 class="col-md-8 col-lg-9 mb-4 float-start">Welcome back, {{ profile.first_name }}</h3>
          <div class="col-md-4 col-lg-3 float-end">
            <div class="row">
              <label for="month" class="form-label fw-bold h5 col-md-4 col-5 mt-1">Filter:</label>
              <span class="col-md-8 col-7">
                <select data="" class="form-select form-control input-group" v-model="year" @change="getAnalytics">
                  <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 pb-3" v-for="item in infoCols">
          <Card>
            <template #default>
              <div class="row align-items-center align-content-center">
                <div class="col-5 justify-content-center">
                  <div
                      :class="[`bg-${item.bg}-light rounded p-4 text-${item.bg}`]"
                      class="text-center"
                  >
                    <vue-feather :type="item.icon" :stroke="item.color" size="50" stroke-width="2"></vue-feather>
                  </div>
                </div>
                <div class="col-7">
                  <div class="font-size-125 fw-bold">{{ item.title }}</div>
                  <div class="font-size-100 color-gray-600">
                    {{ analyticsText(item.desc) }}
                  </div>
                  <div :class="[`mt-3 text-${item.bg} font-size-150 fw-bold`]">
                    {{ item.value }}
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="col-xxl-6">
          <div class="row mb-3">
            <!-- QuickLinks -->
            <div class="col-sm-12">
              <Card>
                <div class="row">
                  <div v-if="profile.role !== 'user'" class="col-sm-3 text-center quick-link" @click="$router.push('/add-product')">
                    <div class="bg-dark-light rounded p-2 mb-2 text-black">
                      <vue-feather type="package" stroke="darkviolet" size="50" stroke-width="1"></vue-feather><br>
                      <span>Add Product</span>
                    </div>
                  </div>
                  <div v-if="profile.role !== 'user'" class="col-sm-3 text-center quick-link" @click="$router.push('/add-supplier')">
                    <div class="bg-dark-light rounded p-2 mb-2 text-black">
                      <vue-feather type="truck" stroke="crimson" size="50" stroke-width="1"></vue-feather><br>
                      <span>Add Supplier</span>
                    </div>
                  </div>
                  <div class="col-sm-3 text-center quick-link" @click="$router.push('/sales')">
                    <div class="bg-dark-light rounded p-2 mb-2 text-black">
                      <vue-feather type="dollar-sign" stroke="green" size="50" stroke-width="1"></vue-feather><br>
                      <span>All Sales</span>
                    </div>
                  </div>
                  <div class="col-sm-3 text-center quick-link" @click="$router.push('/transfers')">
                    <div class="bg-dark-light rounded p-2 mb-2 text-black">
                      <vue-feather type="table" stroke="dodgerblue" size="50" stroke-width="1"></vue-feather><br>
                      <span>Transfers</span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Page visits chart -->
        <div class="col-xxl-6 mb-3">
          <Card>
            <div>
              <div class="font-size-100 color-gray-600">
                Annual Total Sales ({{ this.year }})
              </div>
              <div class="fw-bold font-size-150 text-primary">
                Total: {{ formatNumber(this.analytics.sales.total) }}
              </div>
            </div>
            <div>
              <div v-if="loading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
              <BarChart
                  v-else
                  :data="this.analytics.sales.totals"
                  :labels="this.analytics.sales.months"
              >
              </BarChart>
            </div>
          </Card>
        </div>
        <div class="col-xxl-6 mb-3">
          <Card>
            <div class="font-size-100 color-gray-600">
              Annual Sales Per Store ({{ this.year }})
            </div>
            <div class="fw-bold font-size-150 text-primary">
              Total: {{ formatNumber(this.analytics.sales.total) }}
            </div>
            <div>
              <div v-if="loading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
              <LineChart
                  v-else
                  :data="this.analytics.stores.datasets"
                  :labels="this.analytics.stores.labels"
              ></LineChart>
            </div>
          </Card>
        </div>
      </div>

      <!-- Upload Files Modal -->
      <Modal :open="filesModal" @closed="filesModal = false" dialog-size="modal-lg">
        <div class="modal-header">
          <h5 class="modal-title m-0">Upload Files</h5>
          <button @click="filesModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div v-for="file in neededFiles" class="form-group col-md-6">
                <label for="duration" class="form-label fw-bold h5 mt-3">{{ file }}:</label>
                <input class="form-control" type="file" @input="handleFileUpload($event, file)" name="docs" accept=".jpeg,.png,.jpg,.pdf">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="filesModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" @click="uploadFiles">Submit</button>
        </div>
      </Modal>
      <!-- Upload Files Modal End -->

      <!-- Renew Subscription Modal -->
      <Modal :open="subscriptionModal" @closed="subscriptionModal = false" dialog-size="modal-md">
        <div class="modal-header">
          <h5 class="modal-title m-0">Renew Subscription</h5>
          <button @click="subscriptionModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <div role="alert" class="alert alert-info">
            <div class="content text-center">
              Get one month free! By subscribing for 12 months.
            </div>
          </div>
          <br>
          <span style="display:block; text-align: center">
          <img src="https://intasend-prod-static.s3.amazonaws.com/img/trust-badges/intasend-trust-badge-with-mpesa-hr-dark.png" width="100%" alt="IntaSend">
        </span>
          <hr>
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="duration" class="form-label fw-bold h5 mt-3">Months:</label>
                <select data="" class="form-select form-select-lg" v-model="duration">
                  <option value="1" selected>1 Month</option>
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label for="price" class="form-label h5 mt-3"><strong>Price:</strong> {{ formatNumber(subscription.package.price) }}</label>
                <hr>
                <div class="float-end">
                  <h5 class="text-muted fst-italic">Total: {{ formatNumber(duration * subscription.package.price) }}</h5>
                  <h5 class="text-muted fst-italic">Discount: {{ formatNumber(duration === "12" ? subscription.package.price : 0) }}</h5>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="total" class="form-label fw-bold h4 mt-3 float-end">Total: {{ formatNumber(amount) }}</label>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="subscriptionModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" @click="renewSubscription">Checkout</button>
        </div>
      </Modal>
      <!-- Renew Subscription Modal End -->

      <!-- IntaSend Payment Button -->
      <button
          class="intaSendPayButton d-none"
          ref="payBtn"
          data-country="KE"
          :data-amount="amount"
          :data-email="organization.email"
          data-currency="KES"
      ></button>

    </div>
</template>

<script>
    import analyticsService from "@/api/analyticsService";
    import BarChart from "@/components/charts/BarChart.vue";
    import formatNumber from "../utils/formatNumber";
    import LineChart from "@/components/charts/LineChart.vue";
    import moment from "moment";
    import formatDate from "../utils/formatDate";
    import {decrypt, encrypt} from "@/utils/crypto";
    import 'intasend-inlinejs-sdk';
    import subscriptionService from "@/api/subscriptionService";
    import notify from "@/utils/notify";
    import organizationService from "@/api/organizationService";

    export default {
      name: "Dashboard",
      emits:['confirm', 'cancel'],
      components: {LineChart, BarChart },
      data() {
        return {
          loading: true,
          analytics: {
            sales: {
              months: [],
              totals: []
            },
            stores: {
              labels: [],
              datasets: []
            },
            products: {}
          },
          year: new Date().getFullYear(),
          years: [],
          profile: {},
          subscription: {
            package: {}
          },
          organization: {},
          duration: 1,
          amount: 0,
          subscriptionStatus: '',
          subscriptionModal: false,
          paymentRef: '',
          redirectUrl: process.env.VUE_APP_BASE_URL + 'paymentSuccess',
          employeesNo: 0,
          filesModal: false,
          orgFiles: [],
          requiredFiles: ['Registration Certificate', 'ID Card'],
          neededFiles: [],
          selectedFiles: [],
        }
      },

      methods: {
        formatDate,
        formatNumber,
        getAnalytics: async function () {
          this.analytics = await analyticsService.getAnalytics(this.year)
        },
        getSubscription: async function () {
          if (sessionStorage.getItem('paymentSuccess') === 'true') {
            this.subscription = await subscriptionService.getSubscription()
          } else {
            this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
          }
        },
        getEmployeesNo: async function () {
          if (sessionStorage.getItem('employeesNo') !== null) {
            this.employeesNo = JSON.parse(decrypt(sessionStorage.getItem('employeesNo')))
          } else {
            let employees = [[]];
            this.employeesNo = employees.length
          }
        },
        getSubscriptionStatus: function () {
          let today = moment(new Date())
          let to = moment(this.subscription.to)
          let days = to.diff(today, 'days');
          if (days < 0) {
            this.subscriptionStatus = 'expired'
          } else if (days === 0) {
            if (today.format("YYYY-MM-DD") === to.format("YYYY-MM-DD")) {
              this.subscriptionStatus = 'expired'
            } else {
              this.subscriptionStatus = 'expiring'
            }
          } else if (days < 4 && days > 0) {
            this.subscriptionStatus = 'expiring'
          } else {
            this.subscriptionStatus = 'active'
          }
          this.amount = this.subscription.package.price
          sessionStorage.setItem('subscriptionStatus', encrypt(this.subscriptionStatus))
        },
        renewSubscription: async function () {
          let response = await subscriptionService.initPayment({
            organization_id: this.subscription.organization_id,
            package_id: this.subscription.package.id,
            type: 'renew',
            package: this.subscription.package.name,
            duration: this.duration,
            amount: this.amount,
          })
          if (response.status === 201) {
            this.subscriptionModal = false
            this.$refs.payBtn.setAttribute('data-api_ref', response.data.id)
            this.$refs.payBtn.click()
          }
        },
        determineNeededFiles: function () {
          this.organization.files.forEach(file => {
            this.orgFiles.push(file.name)
          })
          this.requiredFiles.forEach(file => {
            if (!this.orgFiles.includes(file)) {
              this.neededFiles.push(file)
            }
          })
        },
        handleFileUpload(file, name) {
          this.selectedFiles[name] = file
        },
        uploadFiles : async function() {
          this.neededFiles.forEach(name => {
            if (this.selectedFiles[name] === undefined) {
              notify.alert.error('Please select a file for ' + name)
            } else {
              let fileReader = this.processFile(this.selectedFiles[name])
              fileReader.onload = async () => {
                if (typeof fileReader.result === 'string' && Object.keys(this.selectedFiles).length === this.neededFiles.length) {
                  let response = await organizationService.addOrganizationFile({
                    organization_id: this.organization.id,
                    name: name,
                    file: fileReader.result,
                  })
                  if (response) {
                    this.filesModal = false
                    await organizationService.getOrganization()
                    window.location.reload()
                  }
                }
              }
            }
          })
        },
        processFile : function(file) {
          const fileReader = new FileReader()
          const { files } = file.target
          if (files && files.length) {
            fileReader.readAsDataURL(files[0])
            return fileReader
          }
        },
        analyticsText(desc) {
          return desc ? desc : 'In the year ' + this.year
        }
      },

      created: async function () {
        this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
        if (this.profile.email_verified_at === null) {
          this.$router.push('/change-password')
        }
        await this.getSubscription()
        this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
        this.getSubscriptionStatus()
        await this.getAnalytics()
        this.loading = false
        await this.getEmployeesNo()
        this.years = this.analytics.years
        this.determineNeededFiles()
      },

      mounted: async function () {
        new window.IntaSend({
          publicAPIKey: process.env.VUE_APP_INTASEND_API_KEY,
          live: process.env.NODE_ENV !== 'development',
          redirectURL: this.redirectUrl
        })
      },

      computed: {
        infoCols() {
          return [
            {
              title:'Sales (' + this.analytics.sales.count + ')',
              value:formatNumber(this.analytics.sales.total, false),
              icon:"dollar-sign",
              color:"darkviolet",
              bg:'primary',
            },
            {
              title:'Tax (' + this.analytics.sales.count + ')',
              value:formatNumber(this.analytics.sales.tax, false),
              icon:"pie-chart",
              color:"crimson",
              bg:'secondary',
            },
            {
              title:'Discount (' + this.analytics.sales.count + ')',
              value:formatNumber(this.analytics.sales.discount, false),
              icon:"percent",
              color:"dodgerblue",
              bg:'info',
            },
            {
              title:'Stock Pur. (' + this.analytics.products.count + ')',
              value:formatNumber(this.analytics.products.buy, false),
              icon:"package",
              color:"gold",
              bg:'warning',
              desc:'Purchase value',
            },
            {
              title:'Stock Sale (' + this.analytics.products.count + ')',
              value:formatNumber(this.analytics.products.sell, false),
              icon:"package",
              color:"gray",
              bg:'default',
              desc:'Sale value',
            },
            {
              title:'Stock Profit',
              value:formatNumber(this.analytics.products.sell - this.analytics.products.buy, false),
              icon:"trending-up",
              color:"green",
              bg:'success',
              desc: 'Estimated profit'
            },
          ]
        },
      },

      watch: {
        duration: function () {
          if (this.duration === "12") {
            this.amount = this.subscription.package.price * 11
          } else {
            this.amount = this.subscription.package.price * this.duration
          }
        }
      }
    }
</script>

<style scoped>
.quick-link:hover {
  opacity: 70%;
  cursor:pointer;
}
</style>
